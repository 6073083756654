<template>
  <div class="create-complaint">
    <div style="height:0.2rem;"></div>
    <van-form
      class="complaint-form"
      v-if="!noticeShow"
      validate-first
      scroll-to-error
      :show-error="false"
      @submit="onSubmit"
    >
      <div style="background-color:#ffffff;border-radius:8px;padding:10px;">
        <h3 style="padding: 6px 0px;">基本信息</h3>
        <van-field
          v-model.trim="form.complainantName"
          colon
          label="姓名"
          placeholder="请输入姓名"
          required
          :rules="rules.complainantName"
        />
        <van-field name="radio" colon label="性别" required :rules="rules.complainantSex">
          <template #input>
            <van-radio-group v-model="form.complainantSex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model.trim="form.complaintPhone"
          colon
          label="手机号"
          placeholder="请输入手机号码"
          required
          :rules="rules.complaintPhone"
        />
        <van-field
          v-model.trim="form.smscode"
          center
          clearable
          colon
          label="短信验证码"
          required
          :rules="rules.smscode"
          placeholder="请输入短信验证码"
          maxlength=4
        >
          <template #button>
            <van-button
              v-show="show"
              size="small"
              native-type="button"
              type="info"
              @click="getCode"
            >获取验证码</van-button>
            <van-button disabled v-show="!show" size="small">{{count}}秒 后重新发送</van-button>
          </template>
        </van-field>
      </div>
      <div style="background-color:#ffffff;border-radius:8px;padding:10px;margin-top:10px;">
        <h3 style="padding: 6px 0px;">投诉信息</h3>
        <van-field
          readonly
          colon
          clickable
          name="picker"
          :value="value"
          label="投诉类别"
          :rules="rules.accidentCategory"
          placeholder="请选择投诉类别"
          @click="showPicker = true"
          required
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-cascader
            title="请选择投诉类别"
            :options="workorderAccidentCategoryOptions"
            :field-names="fieldNames"
            active-color="#1989fa"
            @close="showPicker = false"
            @finish="onAccidentCategory"
          />
          <!-- <van-picker
            show-toolbar
            value-key="name"
            :columns="workorderAccidentCategoryOptions"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          /> -->
        </van-popup>

        <van-field
          readonly
          colon
          clickable
          name="picker"
          :value="countyName"
          label="举报问题所属单位"
          :rules="rules.countyName"
          placeholder="请选择所属单位"
          @click="showCountyListPicker = true"
          required
        />
        <van-popup v-model="showCountyListPicker" position="bottom">
          <van-cascader
            title="请选择所属单位"
            :options="countyList"
            :field-names="fieldNames"
            active-color="#1989fa"
            @close="showCountyListPicker = false"
            @finish="onSelectCountyName"
          />
        </van-popup>
        <van-field
          v-model.trim="form.location"
          colon
          label="举报问题所在地"
          placeholder="请输入详细位置信息"
        />
        <van-field
          v-model.trim="form.reportSituation"
          rows="2"
          colon
          autosize
          required
          label="简要情况"
          type="textarea"
          maxlength="200"
          placeholder="请输入简要情况"
          :rules="rules.reportSituation"
          show-word-limit
          :border="false"
        />
        <van-notice-bar
          color="#ff0000"
          wrapable
          background="#ecf9ff"
          text="提示：填写简要情况说明中出现的人物、地点、时间以及事件发生过程描述等信息请如实、准确填写"
        />
        <van-field
          v-model.trim="form.appeal"
          rows="2"
          colon
          autosize
          required
          label="办理诉求"
          type="textarea"
          maxlength="200"
          placeholder="请输入办理诉求"
          :rules="rules.appeal"
          show-word-limit
        />
      </div>
      <div style="background-color:#ffffff;border-radius:8px;padding:10px;margin-top:10px;">
        <h3 style="padding: 6px 0px 15px;">附件上传</h3>
        <van-uploader
          :after-read="afterRead"
          :before-read="beforeRead"
          :before-delete="beforeDelete"
          v-model="fileList"
          :max-count="3"
          accept="image/*"
        />
      </div>
      <div style="width:80%;margin:0.5rem auto 0;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <div style="height:0.3rem;"></div>
    <van-overlay
      class="overlay"
      :show="noticeShow"
      :lock-scroll="false"
    >
      <div class="wrapper">
        <div class="block" ref="vanOverlay">
          <Notice v-if="firstStep"></Notice>
          <Notice2 v-if="secondStep"></Notice2>
          <div class="btn">
            <van-button
              size="small"
              :disabled="timeDisabled"
              @click="closeOverlay"
              round
              type="info"
            >
              <span v-if="firstStep">下一步</span>
              <span v-if="secondStep">我已详细阅读</span>
              <span v-if="timeDisabled">（{{btnTime}}</span>
              <span v-if="timeDisabled">s）</span>
            </van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- <van-popup
      class="overlay"
      v-model="noticeShow"
      :lock-scroll="false"
      round
      safe-area-inset-bottom
      :style="{ width: '85%', height:'90%' }"
      :close-on-click-overlay="false"
    >
      <div class="wrapper">
        <div class="block">
          <Notice></Notice>
          <div class="btn">
            <van-button
              size="small"
              :disabled="timeDisabled"
              @click="closeOverlay"
              round
              type="info"
            >
              <span>我已详细阅读</span>
              <span v-if="timeDisabled">（{{btnTime}}</span>
              <span v-if="timeDisabled">s）</span>
            </van-button>
          </div>
        </div>
      </div>
    </van-popup> -->
  </div>
</template>
<script>
import { addWorkComplaint, getSmsCode, uploadFile, getCountyList } from '@/config/apis.js'

import { Toast } from 'vant'
import Notice from '@/components/notice/index'
import Notice2 from '@/components/notice2/index'

export default {
  components: {
    Notice,
    Notice2
  },
  data () {
    return {
      countyName: '',
      countyList: [],
      showCountyListPicker: false,
      firstStep: true,
      secondStep: false,
      pageShow: true,
      show: true,
      noticeShow: true,
      timeDisabled: true,
      showPicker: false,
      timer: null,
      countDownTime: null,
      value: '',
      count: '',
      btnTime: 15,
      fileList: [],
      files: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      workorderAccidentCategoryOptions: [
        {
          label: '咨询类',
          value: 1
        },
        {
          label: '建议类',
          value: 2
        },
        {
          label: '举报投诉类',
          value: 3,
          children: [
            {
              label: '安全生产隐患',
              value: 4
            },
            {
              label: '非法违法行为',
              value: 5
            },
            {
              label: '生产安全事故',
              value: 6
            }
          ]
        }
      ],
      form: {
        complainantName: '',
        complainantSex: '',
        complaintPhone: '',
        smscode: '',
        accidentCategory: '',
        reportSituation: '',
        attach: [],
        location: '',
        appeal: '',
        countyName: ''
      },
      rules: {
        complainantName: [
          { validator: (val) => !!val, message: '必须填写姓名' },
          { validator: (val) => val.length <= 8, message: '姓名长度小于8' }
        ],
        complainantSex: [
          { validator: (val) => !!val, message: '请选择性别' }
        ],
        complaintPhone: [
          { validator: (val) => !!val, message: '请输入手机号' },
          { validator: (val) => !!(/^1\d{10}$/.test(val)), message: '请输入正确的手机号' }
        ],
        smscode: [
          { validator: (val) => !!val, message: '请填写验证码' },
          { validator: (val) => val.length === 4, message: '请输入正确的验证码' }
        ],
        accidentCategory: [
          { validator: (val) => !!val, message: '请选择投诉类别' }
        ],
        countyName: [
          { validator: (val) => !!val, message: '请选择所属单位' }
        ],
        reportSituation: [
          { validator: (val) => !!val, message: '请填写简要情况' }
        ],
        // location: [
        //   { validator: (val) => !!val, message: '请填写位置信息' }
        // ],
        appeal: [
          { validator: (val) => !!val, message: '请填写办理诉求' }
        ]
      }
    }
  },
  created () {
    this.countDown()
    this.fethCountyList()
  },
  mounted () { },
  methods: {
    onAccidentCategory (option) {
      this.showPicker = false
      this.form.accidentCategory = option.selectedOptions.map((item) => item.value)
      this.value = option.selectedOptions.map((item) => item.label).join(' / ')
    },
    onSelectCountyName (option) {
      this.showCountyListPicker = false
      this.form.countyName = option.value
      this.countyName = option.selectedOptions.map((item) => item.label).join(' / ')
    },
    countDown () {
      this.btnTime = 15
      this.countDownTime = setInterval(() => {
        this.btnTime--
        if (this.btnTime === 0) {
          clearInterval(this.countDownTime)
          this.timeDisabled = false
          this.countDownTime = null
        }
      }, 1000)
    },
    closeOverlay () {
      if (this.firstStep && !this.secondStep) {
        this.firstStep = false
        this.secondStep = true
        this.timeDisabled = true
        this.$refs.vanOverlay.scrollTop = 0
        this.countDown()
      } else {
        this.noticeShow = false
      }
    },
    // onConfirm (value) {
    //   this.value = value.name
    //   this.form.accidentCategory = value.value
    //   this.showPicker = false
    // },
    afterRead (files) {
      files.status = 'uploading'
      files.message = '上传中...'
      const file = files.file
      const formData = new FormData()
      formData.append('file', file)
      uploadFile(formData).then(res => {
        if (res.code === 1) {
          console.log('files---', files)
          console.log('res---', res)

          files.status = 'success'
          Toast.success('上传成功')
          this.form.attach.push({
            name: files.file.name,
            uid: files.file.lastModified,
            url: res.data.full_path
          })
        } else {
          Toast.fail(res.msg)
          files.status = ''
          this.fileList.forEach((item, index) => {
            if (item.file.name === files.file.name) {
              this.fileList.splice(index, 1)
            }
          })
        }
      }).catch(() => {
        this.fileList.forEach((item, index) => {
          if (item.file.name === files.file.name) {
            this.fileList.splice(index, 1)
          }
        })
        Toast.fail('服务器繁忙,稍后再试')
      })
    },
    beforeRead (file) {
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        Toast.fail('上传文件大小不能超过 10MB!', 'error')
      }
      return isLt10M
    },
    beforeDelete (file, index) {
      this.form.attach.splice(index.index, 1)
      return true
    },
    getCode () {
      if (!this.form.complaintPhone) {
        Toast.fail('请输入手机号码')
        return
      }
      const regPhone = /^1\d{10}$/
      if (!regPhone.test(this.form.complaintPhone)) {
        Toast.fail('请输入正确的手机号码')
        return
      }
      Toast.loading({
        message: '正在发送...',
        forbidClick: true,
        duration: 0
      })
      getSmsCode({
        complaint_phone: this.form.complaintPhone
      }).then(res => {
        if (res.code === 1) {
          Toast.success('发送成功')
          const TIME_COUNT = 59
          if (!this.timer) {
            this.count = TIME_COUNT
            this.show = false
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.show = true
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        } else {
          Toast.fail(res.msg)
        }
      }).catch(e => {
        Toast.fail('服务器繁忙,稍后再试')
      })
    },
    async fethCountyList () {
      const { data } = await getCountyList()
      this.countyList = data
    },
    onSubmit () {
      Toast.loading({
        message: '提交中...',
        forbidClick: true,
        duration: 0
      })
      const data = {
        complainant_name: this.form.complainantName,
        complainant_sex: this.form.complainantSex,
        complaint_phone: this.form.complaintPhone,
        smscode: this.form.smscode,
        complaint_kind_one: this.form.accidentCategory[0] + '',
        report_situation: this.form.reportSituation,
        attach: JSON.stringify(this.form.attach),
        location: this.form.location,
        appeal: this.form.appeal,
        county_name: this.form.countyName
      }
      this.form.accidentCategory[1] && (data.complaint_kind_two = this.form.accidentCategory[1] + '')
      addWorkComplaint(data).then(res => {
        if (res.code === 1) {
          Toast.success('提交成功')
          this.$router.push({ path: '/complaint/result' })
        } else {
          Toast.fail(res.msg)
        }
      }).catch(() => {
        Toast.fail('服务器繁忙,稍后再试')
      })
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (this.countDownTime) {
      clearInterval(this.countDownTime)
      this.countDownTime = null
    }
  }
}
</script>

<style lang="scss" scoped>
.create-complaint {
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f7;
}
.complaint-form {
  width: 95%;
  height: 100%;
  margin: 0 auto;
}
.overlay {
  overflow-y: auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;
  height: 85%;
  padding: 0.2rem 0.3rem;
  border-radius: 0.2rem;
  overflow-y: auto;
  background-color: #fff;
  .btn {
    margin-top: 0.5rem;
    text-align: right;
    .van-button--small {
      width: 140px;
    }
  }
}
</style>
